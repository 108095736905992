import React, { useState } from 'react'
import {
  CompanyInterface,
  RepresentativeInterface
} from '@root/src/prismic/lib/dataResolvers'
import {
  useModal,
  BasicCard,
  getUniqueTags,
  getTrimmedExcerpt
} from '@islandsstofa/react'
import Img from 'gatsby-image'
import { CompanyAndRepresentativeModalTemplateProps } from '../Modal/templates/CompanyAndRepresentative'
import TagFilter from '@cmp/site/TagFilter'
import slugify from 'slugify'

export type CompanyCardGridProps = {
  title: string
  cards: {
    company?: CompanyInterface
    representative: RepresentativeInterface
    tags: string[]
    useOnlyRep: boolean
  }[]
}

const CompanyCardGrid = ({ title, cards }: CompanyCardGridProps) => {
  const { executeModal } = useModal()

  const [chosenTags, setChosenTags] = useState<string[]>([])

  const updateChosenTags = (tag?: string) => {
    if (!tag) setChosenTags([])
    else {
      setChosenTags(prev => {
        if (prev.includes(tag)) {
          return prev.filter(t => t !== tag)
        } else {
          return [...prev, tag]
        }
      })
    }
  }

  const getFilteredCards = (cards: CompanyCardGridProps['cards']) =>
    chosenTags.length > 0
      ? cards.filter(card =>
          card.tags.some(tag =>
            chosenTags.includes(slugify(tag, { lower: true }))
          )
        )
      : cards

  const tags = getUniqueTags(cards)
  const filteredCards = getFilteredCards(cards)

  const getModalContent = (card: CompanyCardGridProps['cards'][0]) => {
    const { representative: rep, company, useOnlyRep } = card
    const getLinks = () => {
      const links = []
      if (rep.email) {
        links.push({
          url: 'mailto:' + rep.email,
          label: 'Email'
        })
      }
      if (rep.phone) {
        links.push({
          url: 'tel:' + rep.phone,
          label: 'Phone'
        })
      }
      if (useOnlyRep && rep.website?.url) {
        links.push({
          url: rep.website.url,
          label: 'Website'
        })
      } else if (company?.website?.url) {
        links.push({
          url: company.website.url,
          label: 'Website'
        })
      }
      return links
    }
    const content = useOnlyRep
      ? {
          image: rep.portrait,
          secondaryImage: undefined,
          title: rep.fullName.text,
          subtitle: rep.role,
          text: rep.about ?? { html: '' },
          links: getLinks()
        }
      : {
          image: rep.portrait,
          secondaryImage: company?.image,
          title: rep.fullName.text,
          subtitle: rep.role,
          text: company?.about ?? { html: '' },
          links: getLinks()
        }
    return content
  }

  const getContentForAllModals = (): CompanyAndRepresentativeModalTemplateProps[] =>
    filteredCards.map(card => getModalContent(card))

  return (
    <div className='page-slice-spacer container'>
      <h2 className='mt-4 mb-4'>{title}</h2>
      {tags.length > 1 && (
        <div className='col-lg-6 pl-0 mb-3'>
          <TagFilter
            allTags={tags}
            chosenTags={chosenTags}
            update={updateChosenTags}
          />
        </div>
      )}
      <div className='data-card-grid'>
        {filteredCards.map((card, key) => {
          const content = card.useOnlyRep
            ? {
                ...card.representative,
                image: card.representative.portrait,
                name: card.representative.fullName.text
              }
            : card.company

          return (
            <button
              key={key}
              onClick={() =>
                executeModal(
                  getContentForAllModals(),
                  key,
                  'modal__green-tech-partner',
                  'CompanyAndRepresentative'
                )
              }
            >
              <BasicCard
                onRenderImage={() =>
                  content?.image ? (
                    <Img
                      fluid={content.image.fluid}
                      alt={content.image.alt}
                      className='border image-rounded'
                      imgStyle={{
                        objectFit: 'contain',
                        padding: '10px'
                      }}
                    />
                  ) : (
                    <div>Missing Portrait</div>
                  )
                }
              >
                <h4>{content?.name}</h4>
                <div
                  className='data-card-grid__excerpt'
                  dangerouslySetInnerHTML={{
                    __html: getTrimmedExcerpt('p', content?.excerpt)?.html ?? ''
                  }}
                />
              </BasicCard>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default CompanyCardGrid
